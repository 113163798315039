




































import { Component, Vue, Watch } from 'vue-property-decorator'
import firebase from 'firebase/app'
import { auth } from '@/api/firebase'
import { Dictionary, DictionaryRef } from '@/api/dictionary'
import * as queries from '@/api/dictionaries'
import DictionaryCardGroup from '@/components/DictionaryCardGroup.vue'

interface QSetter {
  (d: (Dictionary | undefined)[]): void
}

@Component({
  components: {
    DictionaryCardGroup
  }
})
export default class Home extends Vue {
  private user: firebase.User | null = null
  private showNewAlert = localStorage.getItem('app--hide-new-app') !== 'true'
  private errorMessage: string | null = null

  private myDictionaries: (Dictionary | undefined)[] = []

  private favoriteDictionaries: (Dictionary | undefined)[] = []

  private laterDictionaries: (Dictionary | undefined)[] = []

  private topDictionaries: (Dictionary | undefined)[] = []

  updateStaticList(q: firebase.firestore.Query<Dictionary> | undefined, setter: QSetter): void {
    if (q) {
      queries.get(q)
        .then(setter)
        .catch(e => {
          this.errorMessage = e
        })
    } else {
      setter([])
    }
  }

  updateDynamicList(q: firebase.firestore.Query<Dictionary> | undefined, setter: QSetter): void {
    if (q) {
      queries.onSnapshot(q, setter)
    } else {
      setter([])
    }
  }

  updateRefList(q: firebase.firestore.Query<DictionaryRef> | undefined, setter: QSetter): void {
    if (q) {
      queries.onSnapshotRef(q, setter)
    } else {
      setter([])
    }
  }

  created (): void {
    auth.onAuthStateChanged(user => {
      this.user = user

      this.updateDynamicList(queries.getMyDictionaries(6), d => this.myDictionaries = d)
      this.updateRefList(queries.getViewLater(6), d => this.laterDictionaries = d)
      this.updateRefList(queries.getFavorites(6), d => this.favoriteDictionaries = d)
    })

    this.updateStaticList(queries.getPopular(6), d => this.topDictionaries = d)
  }

  @Watch('showNewAlert')
  watchNewapp (value: boolean): void {
    localStorage.setItem('app--hide-new-app', (!value).toString())
  }
}
